<template>
  <TTDataTable
    class="import-history-table"
    :headers="headers"
    :items="items"
    :loading="loading"
    disable-pagination
    hide-default-footer
    data-test="tt-table"
    data-test-value="import-history-table"
  >
    <template #item.author="{item}">
      <div class="d-flex">
        <TTAvatar
          v-if="item.userId"
          :src="avatarUrl(item.author.id)"
          :text="avatarText(item)"
          x-small
          class="mr-2"
        />
        <span class="import-history-author">
          {{ item.fullName }}
        </span>
      </div>
    </template>

    <template #item.filesCount="{item}">
      <div class="d-flex justify-center">
        {{ item.state === IMPORT_STATE.PROCESSING ? '—' : item.filesCount }}
      </div>
    </template>

    <template #item.errorsCount="{item}">
      <div
        class="d-flex justify-center"
        :class="{ 'tt-light-red--text' : item.isError }"
      >
        {{ item.state === IMPORT_STATE.PROCESSING ? '—' : item.errorsCount }}
      </div>
    </template>

    <template #item.actions="{item}">
      <div class="d-flex justify-space-between align-center">
        <TTChip
          :style="chipStyle(item)"
          small
        >
          {{ item.statusText }}
        </TTChip>
        <TTBtn
          class="tt-secondary ml-1"
          color="#E5F8FF"
          data-test-value="import-history-table-more-btn"
          data-test="tt-btn"
          height="28"
          @click="toImport(item)"
        >
          Подробнее
        </TTBtn>
      </div>
    </template>
  </TTDataTable>
</template>

<script>
import { avatarText, getGoghaImageUrl } from '@front.backoffice/common';
import { IMPORT_STATE } from '../import/constants';

export default {
  name: 'CompanyImportHistory',
  inject: ['Names'],
  props: {
    items: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: '№',
          value: 'importId',
          sortable: false,
          width: 70,
        },
        {
          text: 'Дата',
          value: 'createdAt',
          width: '15%',
        },
        {
          text: 'Автор',
          value: 'author',
          sortable: false,
          width: '35%',
        },
        {
          text: 'Файлов',
          value: 'filesCount',
          sortable: false,
          width: '7%',
        },
        {
          text: 'Ошибок',
          value: 'errorsCount',
          sortable: false,
          width: '7%',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      IMPORT_STATE,
    };
  },
  methods: {
    async toImport(item) {
      if (item.state === IMPORT_STATE.INITIAL) {
        await this.$router.push({
          name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_UPLOAD,
          params: { importId: item.importId },
        });
      }
      await this.$router.push({
        name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_PROCESSING,
        params: { importId: item.importId },
      });
    },
    avatarUrl(userId) {
      return getGoghaImageUrl({
        uuid: userId,
        name: 'avatar',
        type: 'user',
      });
    },
    avatarText(item) {
      return avatarText(item?.author?.firstName, item?.author?.lastName);
    },
    chipStyle(item) {
      if (item.isError && item.state === IMPORT_STATE.FINISH) {
        return {
          color: '#EF323F',
          'background-color': '#FFE2E2 !important',
        };
      }
      if (!item.isError && (item.state === IMPORT_STATE.FINISH)) {
        return {
          color: '#00A345',
          'background-color': '#D4F8E7 !important',
        };
      }
      return {
        color: '#63676B',
        'background-color': '#F5F6F6 !important',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.table-btn {
  width: 100%;
  padding: 0 !important;
}

.import-history-author {
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.statusError {
  background-color: #FFE2E2 !important;
  color: #EF323F !important;
}

::v-deep table tbody tr td {
  border-bottom: 1px solid map-get($tt-light-mono-8, 'base') !important;
  background-color: map-get($tt-light-mono-0, 'base') !important;
}

::v-deep table tbody tr td:not(:last-child) {
  border-right: none !important;
}
</style>
