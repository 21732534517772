<template>
  <div class="d-flex justify-center flex-column align-center">
    <div class="tt-text-body-1">
      Здесь будут все импорты компании, в том числе незавершенные
    </div>
    <VImg
      :src="require('@/assets/import-no-data.png')"
      data-test-value="import-no-data-image"
    />
  </div>
</template>

<script>
export default {
  name: 'CompanyImportNoData',
};
</script>
