var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('TTDataTable',{staticClass:"import-history-table",attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.loading,"disable-pagination":"","hide-default-footer":"","data-test":"tt-table","data-test-value":"import-history-table"},scopedSlots:_vm._u([{key:"item.author",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[(item.userId)?_c('TTAvatar',{staticClass:"mr-2",attrs:{"src":_vm.avatarUrl(item.author.id),"text":_vm.avatarText(item),"x-small":""}}):_vm._e(),_c('span',{staticClass:"import-history-author"},[_vm._v(" "+_vm._s(item.fullName)+" ")])],1)]}},{key:"item.filesCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_vm._v(" "+_vm._s(item.state === _vm.IMPORT_STATE.PROCESSING ? '—' : item.filesCount)+" ")])]}},{key:"item.errorsCount",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center",class:{ 'tt-light-red--text' : item.isError }},[_vm._v(" "+_vm._s(item.state === _vm.IMPORT_STATE.PROCESSING ? '—' : item.errorsCount)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('TTChip',{style:(_vm.chipStyle(item)),attrs:{"small":""}},[_vm._v(" "+_vm._s(item.statusText)+" ")]),_c('TTBtn',{staticClass:"tt-secondary ml-1",attrs:{"color":"#E5F8FF","data-test-value":"import-history-table-more-btn","data-test":"tt-btn","height":"28"},on:{"click":function($event){return _vm.toImport(item)}}},[_vm._v(" Подробнее ")])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }