<template>
  <div class="pb-4 pl-3">
    <VRow>
      <VCol>
        <span
          data-test-value="import-title"
          class="tt-text-headline-1 tt-light-mono-100--text"
        >
          Импорт данных оргструктуры
        </span>
      </VCol>
    </VRow>

    <VRow class="mt-1">
      <VCol>
        <p
          class="tt-text-body-1 ma-0"
          data-test-value="import-subtitle"
        >
          {{ $t('import.upload.description' ) }}
        </p>
      </VCol>
    </VRow>

    <VRow class="mt-0">
      <VCol class="pt-1">
        <a
          class="tt-text-body-1 ma-0 text-decoration-none"
          data-test-value="import-help-link"
          href="https://storage.talenttech.ru/public/files/import/manual.pdf"
          target="_blank"
        >
          {{ $t('import.prepare-file' ) }}
        </a>
        <span class="tt-light-mono-46--text tt-light-mono-4 tt-text-body-2 px-2 py-1 ml-1 rounded-xl">PDF</span>
      </VCol>
    </VRow>

    <VRow class="mt-4">
      <VCol class="pt-1">
        <TTBtn
          :loading="createImportLoading"
          class="tt-primary pl-3 tt-text-button-2"
          data-test-value="import-btn"
          data-test="tt-btn"
          @click="createImport"
        >
          <VIcon
            class="mr-2"
          >
            fa-solid fa-file-import
          </VIcon>
          Импортировать файл
        </TTBtn>
      </VCol>
    </VRow>

    <VRow v-if="modifiedImports.length">
      <VCol>
        <div class="tt-text-subtitle mb-4">
          История импорта
        </div>
        <CompanyImportHistory
          :items="modifiedImports"
          :loading="loading"
        />
        <TTBtn
          v-if="importCounter"
          :loading="paginationLoading"
          class="tt-text-button-3"
          color="tt-ghost"
          plain
          @click="loadMoreImports"
        >
          Показать еще {{ importCounter }}
        </TTBtn>
      </VCol>
    </VRow>

    <VRow
      v-else
      class="mt-10"
    >
      <VCol>
        <CompanyImportNoData
          class="mt-10"
          data-test-value="import-history-table-no-data"
        />
      </VCol>
    </VRow>
  </div>
</template>

<script>
import { fio } from '@front.backoffice/common';
import { IMPORT_STATE } from '../../../components/import/constants';
import CompanyImportHistory from '../../../components/v2/CompanyImportHistory.vue';
import CompanyImportNoData from '../../../components/v2/CompanyImportNoData.vue';

export default {
  name: 'CompanyImport',
  components: { CompanyImportNoData, CompanyImportHistory },
  inject: ['Names'],

  props: {
    companyId: { type: String, required: true },
  },

  data() {
    return {
      loading: false,
      createImportLoading: false,
      paginationLoading: false,
      imports: [],
      allImportsCounter: null,
      currentPaginationPage: 0,
    };
  },

  computed: {
    importCounter() {
      if ((this.allImportsCounter - this.imports.length) > 10) {
        return 10;
      }
      return this.allImportsCounter - this.imports.length;
    },
    modifiedImports() {
      return this.imports.map((el) => ({
        ...el,
        createdAt: this.$dayjs(el.updatedAt ? el.updatedAt : el.createdAt).format('DD.MM.YYYY, HH:mm'),
        fullName: fio(el?.author?.lastName, el?.author?.firstName, el?.author?.middleName),
        isError: !!el.errorsCount,
        statusText: this.statusText(el),
      }));
    },
  },

  async created() {
    await this.getImports();
  },

  methods: {
    async loadMoreImports() {
      this.paginationLoading = true;
      try {
        const response = await this.$di.api.FileToStream.ImportsIndex(
          {
            companyId: this.companyId,
            page: this.currentPaginationPage + 1,
            limit: 10,
          },
        );

        const newImports = await this.addUserInfoToImports(response.imports);
        this.imports = [...this.imports, ...newImports];
        this.currentPaginationPage += 1;
      } catch (e) {
        this.$di.notify.errorHandler(e);
      } finally {
        this.paginationLoading = false;
      }
    },
    async getImports() {
      this.loading = true;
      try {
        const response = await this.$di.api.FileToStream.ImportsIndex(
          { companyId: this.companyId, page: 1, limit: 10 },
        );

        this.imports = await this.addUserInfoToImports(response.imports);
        this.allImportsCounter = response.total;
        this.currentPaginationPage = 1;
      } catch (e) {
        this.$di.notify.errorHandler(e);
      } finally {
        this.loading = false;
      }
    },
    async createImport() {
      this.createImportLoading = true;
      try {
        const response = await this.$di.api.FileToStream.CreateImport({ companyId: this.companyId });
        await this.$router.push(
          { name: this.Names.R_ACCOUNT_V2_COMPANY_IMPORT_UPLOAD, params: { importId: response.importId } },
        );
      } catch (e) {
        this.$di.notify.errorHandler(e);
      } finally {
        this.createImportLoading = false;
      }
    },
    async addUserInfoToImports(imports) {
      if (!imports.length) {
        return imports;
      }

      const filteredUsersId = imports.filter((el) => (el.userId));
      if (!filteredUsersId.length) {
        return imports;
      }

      const usersId = filteredUsersId.map((el) => (el.userId));
      const { users } = await this.$di.api.Authentication.getUsers({ ids: usersId });

      return imports.map((el) => {
        const currentUser = users.find((user) => user.id === el.userId);

        return {
          ...el,
          author: {
            id: el.userId,
            firstName: currentUser?.firstName,
            lastName: currentUser?.lastName,
            middleName: currentUser?.middleName,
          },
        };
      });
    },
    statusText(item) {
      if ([IMPORT_STATE.INITIAL, IMPORT_STATE.PROCESSING].includes(item.state)) {
        return 'В процессе';
      }
      if (item.state === IMPORT_STATE.FINISH && !item.errorsCount) {
        return 'Успешно';
      }
      return 'С ошибками';
    },
  },
};
</script>

<style lang="scss" scoped>
.import-back {
  position: absolute;
  top: 35px;
  left: 130px;
  width: 44px;
}
.import-back-btn {
  box-shadow: 0 2px 16px rgba(11, 18, 24, 0.12) !important;
}
</style>
