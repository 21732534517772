// eslint-disable-next-line import/prefer-default-export
export const IMPORT_STATE = {
  INITIAL: 'IMPORT_STATE_INITIAL',
  PROCESSING: 'IMPORT_STATE_PROCESSING',
  FINISH: 'IMPORT_STATE_FINISH',
  ERROR: 'IMPORT_STATE_ERROR',
};

export const IMPORT_FILE_STATE = {
  INITIAL: 'IMPORT_FILE_STATE_INITIAL',
  PROCESSING: 'IMPORT_FILE_STATE_PROCESSING',
  FINISH: 'IMPORT_FILE_STATE_FINISH',
  ERROR: 'IMPORT_FILE_STATE_ERROR',
  VALIDATION: 'IMPORT_FILE_STATE_VALIDATION',
  VALIDATION_SUCCESS: 'IMPORT_FILE_STATE_VALIDATION_SUCCESS',
  VALIDATION_WARNING: 'IMPORT_FILE_STATE_VALIDATION_WARNING',
  VALIDATION_FAILURE: 'IMPORT_FILE_STATE_VALIDATION_FAILURE',
};

export const VALID_STATES = [IMPORT_FILE_STATE.VALIDATION_SUCCESS, IMPORT_FILE_STATE.VALIDATION_WARNING];
export const FILES_STATES = [...VALID_STATES, IMPORT_FILE_STATE.VALIDATION_FAILURE];
export const TYPES = {
  users: 'IMPORT_FILE_TYPE_USERS',
  staff_positions: 'IMPORT_FILE_TYPE_STAFF_POSITIONS',
  teams: 'IMPORT_FILE_TYPE_TEAMS',
};
